
// 判断是否是对象
function isObj(param) {
    return Object.prototype.toString.call(param) === '[object Object]'
}

// 获取cookie
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
} 

// 设置cookie
function setCookie(obj) {
    // 如果传入的参数是对象 设置cookie
    if (isObj(obj)) {
        for(let key in obj) {
            document.cookie = `${key}=${obj[key]};`
        }
    }
}

// 计算文件大小
function getFileSize(size) {
    const sizeType = ['B', 'KB', "MB"]
    let finalSize = size

    for (let sizeLevel = 0; sizeLevel < sizeType.length; sizeLevel++) {
        if (finalSize > 1024) {
            finalSize = finalSize / 1024
            continue;
        }else{
            finalSize = finalSize.toFixed(2) + sizeType[sizeLevel]
            break;
        } 
    }
    return finalSize
}
// 根据传入类型判断文件是否是图片
function isPic(type) {
    let picSuffixStr = 'jpg,jpeg,gif,png,bmp,pic'
    let picSuffixArray = picSuffixStr.split(',')
    return picSuffixArray.includes(type)
}

module.exports = {
    getCookie,
    setCookie,
    getFileSize,
    isPic
}