const crypto = require('crypto');

// md5 加密字符串
function md5Encrypt(str) {
    return crypto.createHash('md5').update(str).digest('hex')
}

// function test() {
//    const testStr = 'jerry'
//     const encryptStr = crypto.createHash('md5').update(testStr).digest('hex')
//     console.log(encryptStr) // 期望值为 30035607ee5bb378c71ab434a6d05410
//     result = encryptStr == '30035607ee5bb378c71ab434a6d05410'
//     console.log(`测试结果为: ${result ? '通过测试': '未通过测试'}`)
// }
// test();

module.exports = {
    md5Encrypt
}