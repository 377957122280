import React, { useState } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import menus from './menus';
// import checkAuth from '../../auth/checkAuth';
import scss from './index.module.scss';
import { getCookie } from '../../utils/utils';
import logoImage from '../../assets/logo200.png'

const { SubMenu } = Menu;

function renderMenu(menu) {
    const Icon = menu.icon;


    if (menu.subMenus && menu.subMenus.length > 0) {
        const subMenus = menu.subMenus

        return (
            <SubMenu key={menu.key}
                title={
                    <span>
                        <Icon />
                        <span>{menu.title}</span>
                    </span>
                }>
                {subMenus.map(subMenu => renderMenu(subMenu))}        
            </SubMenu>
        );
    } else {
        return (
            <Menu.Item key={menu.key}>
                <Link className={scss.link} to={menu.href}>
                    <Icon />
                    <span>{menu.title}</span>
                </Link>
            </Menu.Item>
        );
    }
}

// function getHrefMenuKeys(menus, href) {
//     let menuKeys = [];
//     for (let menu of menus) {
//         if (menu.subMenus) {
//             menuKeys = menuKeys.concat(getHrefMenuKeys(menu.subMenus, href))
//         } else if (menu.href === href) {
//             return menuKeys;
//         }
//     }
// }

function getDefaultOpenKeysAndSelectedKeys() {
    // const href = window.location.hash.replace('#', '');
    let defaultOpenKeys, defaultSelectedKeys;
    // for(let menu of menus) {
    //     if(menu.subMenus) {

    //         defaultOpenKeys.push(menu.key);
    //         let flag = true;
    //         let subMenus = menu.subMenus;
    //         while(flag) {
    //             for(let subMenu of subMenus) {
    //                 if(subMenu.href === href) {
    //                     defaultSelectedKeys = [subMenu.href];
    //                     break;
    //                 }else if(subMenu.subMenus) {

    //                 }
    //             }
    //         }
    //     }else {
    //         defaultOpenKeys = [];
    //     }
    // }
    return {
        defaultOpenKeys,
        defaultSelectedKeys
    }
}

export default function NavMenu() {
    const [collapsed] = useState(false);
    const { defaultOpenKeys, defaultSelectedKeys } = getDefaultOpenKeysAndSelectedKeys();
    
    const username = getCookie('userName');

    return (
    <div className={scss.navMenu}>
        <div className={scss.userInfo}>
            <img alt="logo" src={logoImage} />
            <div className={scss.username}>{username}</div>
        </div>
        <Menu
            defaultSelectedKeys={defaultSelectedKeys}
            defaultOpenKeys={defaultOpenKeys}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
        >
            {menus.map(menu => renderMenu(menu))}
        </Menu>
    </div>
    )
}