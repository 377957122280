import React, {useEffect, useState} from 'react'
import { Table, Button, message } from 'antd'
import { ReloadOutlined } from '@ant-design/icons';
import { getShareFileDownloadRankingList } from '../../apis/user'



export default function DownloadRankingList(props) {

    const columns = [{
        title: '排名',
        width: 50,
        align: 'center',
        render: (text, item, index) => <div style={{ align: 'center' }}>{++index}</div>
    },{
        title: '文件名',
        width: 100,
        align: 'center',
        dataIndex: 'filename',
    },{
        title: '下载量',
        width: 100,
        align: 'center',
        dataIndex: 'pv'
    }]

    // 点击刷新按钮时(做一下防抖)
    const onRefreshBtnClick = () => {
        if (refreshTimer) {
            console.log('刷新按钮冷却中...')
            return 
        }
        refreshTable();
        setRefreshTimer(setTimeout(() => {
            setRefreshTimer(null)
        }, 1000))
    }

    // 刷新
    const refreshTable = () => {
        getShareFileDownloadRankingList({count: pageConfig.page_size, start:0})
        .then(data => {
            const {code, files = []} = data
            // console.log('获取的排行榜响应数据', data)
            setDataList(files)
            
        }).catch(error => {
            console.log('获取排行榜响应数据失败', error)
        })
    }

    // 页面更改时
    const changePage = (page) => {
        const start = (page-1) * pageConfig.page_size

        setPage({ ...pageConfig, page_no: page})
        getShareFileDownloadRankingList({count: pageConfig.page_size, start: start})
        .then(data => {
            const {code, total, files = []} = data
            // console.log('获取的排行榜响应数据', data)
            setDataList(files)
            
        }).catch(error => {
            console.log('获取排行榜响应数据失败', error)
        })
        
    }

    const [total, setTotal] = useState(0)
    const [dataList, setDataList] = useState([])
    const [refreshTimer, setRefreshTimer] = useState(null)

    const [pageConfig, setPage] = useState({
        total_page: 0, // 总页数
        page_no: 1, // 当前页码
        page_size: 10 // 每页数据条数
    });
    
    // 首次刷新
    useEffect(() => {
        getShareFileDownloadRankingList({count:pageConfig.page_size, start:0})
        .then(data => {
            const {code, total, files = []} = data
            // console.log('获取的排行榜响应数据', data)
            setDataList(files)
            setTotal(total)
        }).catch(error => {
            message.error('获取排行榜数据失败')
        })
    }, [])

    // 分页设置
    const pagination = {
        position: ['bottomRight'],
        size: 'small',
        defaultCurrent: 1,
        total: total,
        pageSize: pageConfig.page_size,
        current: pageConfig.page_no,
        showTotal: total => `共${total}条`,
        onChange: changePage
    }

    return (
        <div>
            <div style={{marginLeft: 10 + 'px', display: 'flex'}}>
                <Button 
                    icon={<ReloadOutlined />}
                    onClick={onRefreshBtnClick}
                >刷新</Button>
            </div>
            <div className="container">
                <Table columns={columns}  
                    dataSource={dataList}
                    total={total}  
                    rowKey={(item, index) => index}
                    pagination={pagination} 
                />
            </div>
        </div>
        
    )
}