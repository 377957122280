import React, { useEffect, useState } from 'react';
import { CloudUploadOutlined, ShareAltOutlined, ClearOutlined, ReloadOutlined, ExclamationCircleOutlined, SwitcherOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message, Tag } from 'antd';

import { getShareImageHostingInfo, shareMyImage, browseShareImage, cancelShareImage } from '../../../apis/user';
import { getCookie, getFileSize } from '../../../utils/utils'
import { useLocation } from 'react-router';
// import { HOST } from '../../../constants/config'

const { confirm } = Modal

export default function MyImageHosting(props) {

    const columns = [
        {
            title: '序号',
            width: 50,
            align: 'center',
            fixed: 'left',
            render: (text, item, index) => <div style={{ width: 50 }}>{++index}</div>
        },
        {
            title: '用户名',
            width: 70,
            dataIndex: 'user',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '图片名称',
            width:150,
            dataIndex: 'file_name',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '浏览次数',
            width:100,
            align: 'center',
            dataIndex: 'pv'
        },
        {
            title: '图片提取地址',
            width: 150,
            align: 'center',
            dataIndex: 'urlmd5',
            render: (text, item, index) => <div>{ getImageShareUrl(item.urlmd5) }</div>
        },
        {
            title: '创建时间',
            width: 100,
            align: 'center',
            dataIndex: 'create_time'
        },
        {
            title: '操作',
            align: 'center',
            fixed: 'right',
            render: (text, item) => {
                return (
                    <>
                        <Button type="primary" size="small" color="green" onClick={() => onCancelShareImage(item)}>取消分享</Button>
                        <Button style={{marginLeft: 20}} type="primary" size="small" color="green" onClick={() => copyImageUrl(item)}>复制链接</Button>
                    </>
                )
            }
        }
    ]

    // 表格checkbox配置
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows)
        }
    };
    
    // 刷新表格
    const refreshTable = () => {
        
        getShareImageHostingInfo({token, user, count: pageConfig.page_size, start: 0})
        .then(data => {
            const { code, files = [], total = 0 } = data;
            setData(files);
            setTotal(total)
            message.success('刷新表格成功')
        })
        
    }
    
    
    // 取消分享图片
    const onCancelShareImage = (item) => {
        cancelShareImage({user, token, urlmd5: item.urlmd5 }).then(data => {
            const { code } = data
            code === 0 ? message.success('文件取消分享成功') : message.error('文件取消分享失败');
            // 刷新表格
            refreshTable()
        })
    }

    // 多选时取消分享按钮(暂时不用,只能选中单个后取消分享)
    const onCancelShareImages = () => {
        const selectedCounts = selectedRows.length
        // 如果未选中 提示不支持
        if (selectedCounts === 0) {
            return message.error('请先选中文件后再进行取消分享', 1)
        }
        // 如果选择的文件数大于1 提示暂不支持批量分享
        if (selectedCounts > 1) {
            return message.error('暂不支持批量取消分享', 1)
        }
        
        const selectedFile = selectedRows[0]
        const { urlmd5  } = selectedFile
        // 加入取消分享图片的逻辑
        cancelShareImage({ urlmd5, token, user }).then(data => {
            const { code } = data
            if (code === 0) {
                message.success('图片取消分享成功')
            }else{
                let errMsg = code === 1 ? '取消分享失败' : 'token验证失败'
                message.error(errMsg)
            }
            refreshTable()
        })

    }
    // 将 主机地址和 urlmd5等拼接成图片提取链接
    const getImageShareUrl = (urlmd5) => {
        return `${window.location.hostname}/share?urlmd5=${urlmd5}`
    }

    // 页码更改时
    const changePage = (page) => {
        const start = (page-1) * pageConfig.page_size

        setPage({ ...pageConfig, page_no: page})
        getShareImageHostingInfo({ token, user, count: pageConfig.page_size, start: start})
        .then(data => {
            const {code, total, files = []} = data
            // console.log('获取的我的分享图片响应数据', data)
            setData(files)
            setTotal(total)
            
        }).catch(error => {
            console.log('获取我的分享图片响应数据失败', error)
        })   
    }
    // 复制提取成功的图片url
    const copyImageUrl = (item) => {
        
        let transfer = document.createElement('input');
        document.body.appendChild(transfer);
        transfer.value = getImageShareUrl(item.urlmd5)  // 这里表示想要复制的内容
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
        }
        transfer.blur();
        message.success('已复制到剪切板')
        document.body.removeChild(transfer);      
    }
    // 表格上面的复制链接按钮
    const copyImageUrls = () => {
        const selectedCounts = selectedRows.length
        // 如果未选中 提示不支持
        if (selectedCounts === 0) {
            return message.error('请先选中图片后再进行复制链接', 1)
        }
        // 如果选择的文件数大于1 提示暂不支持批量分享
        if (selectedCounts > 1) {
            return message.error('暂不支持批量复制链接', 1)
        }
        const selectedImage = selectedRows[0]
        copyImageUrl(selectedImage)
    }


    const [token, setToken] = useState(getCookie('token'))
    const [user, setUser] = useState(getCookie('userName'))
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [selectionType, setSelectionType] = useState('checkbox');
    const [selectedRows, setSelectedRows] = useState([])
    const location = useLocation()

    const [pageConfig, setPage] = useState({
        total_page: 0, // 总页数
        page_no: 1, // 当前页码
        page_size: 10 // 每页数据条数
    });

    // 分页设置
    const pagination = {
        position: ['bottomRight'],
        size: 'small',
        defaultCurrent: 1,
        total: total,
        pageSize: pageConfig.page_size,
        current: pageConfig.page_no,
        showTotal: total => `共${total}条`,
        onChange: changePage
    }
    
    // 获取我的共享图片数据
    useEffect(() => {
        getShareImageHostingInfo({token, user, start: 0, count: pageConfig.page_size})
        .then(data => {

            // console.log('获取的我的图片分享数据:', data)

            const { code, total, files=[] } = data
            if (code === 0 && total === 0) {
                // count为0时不解析files
                setData([]);
                setTotal(0)
            }else{
                setData(files);
                setTotal(total)
            }
        })
    }, [])

    
    return (
        <div>
            <div style={{marginLeft: 10 + 'px', display: 'flex'}}>
                
                <Button
                    icon={<ShareAltOutlined />}
                    onClick={onCancelShareImages}
                >取消分享</Button>
                <Button 
                    style={{marginLeft: 20}}
                    icon={<CopyOutlined />}
                    onClick={copyImageUrls}
                >复制链接</Button>
                <Button 
                    style={{marginLeft: 20}}
                    icon={<ReloadOutlined />}
                    onClick={refreshTable}
                >刷新</Button>
            </div>
            
            <div className="container">
                <Table columns={columns}  
                    dataSource={data}
                    total={total} 
                     
                    rowKey={(record, index) => index}
                    pagination={pagination}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }} 
                />
            </div>

        </div>
    )
}