import React, { useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, Form, message } from 'antd';
import { useLocation, Redirect, Link } from 'react-router-dom';
import { login } from '../../apis/user';
import { md5Encrypt } from '../../utils/crypto';
import './index.scss'
import checkLoginToken from '../../auth/checkAuth';
import { setCookie } from '../../utils/utils'


export default function Login(props) {
    const location = useLocation();
    const { from } = location.state || { from: { pathname: "/" } };
    // 检查cookies中是否有token
    const isLoggedIn = checkLoginToken()
    const [loggedIn, setLoggedIn] = useState(isLoggedIn);
    const [loading, setLoading] = useState(false);


    // 校验user字段的校验器 6-16位
    const userValidator = (_, value) => {
        return value && value.length >= 4 && value.length <= 16 ? Promise.resolve() : Promise.reject('用户名长度为4-16位字符')
    }
    // 校验pwd字段的校验器 6-16位
    const pwdValidator = (_, value) => {
        return value && value.length >= 4 && value.length <= 16 ? Promise.resolve() : Promise.reject('密码长度为4-16字符') 
    }

    const onFinish = (values) => {
        const { user, pwd } = values;
        setLoading(true);
        // 对密码进行md5加密
        const encryptPwd = md5Encrypt(pwd);
        login({ user, pwd: encryptPwd }).then(data => {
            setLoading(false);
            // 登录成功后获取token并保存
            const {code, token} = data
            switch (code) {
                case 1:
                    message.error('登录失败')
                    break;
            
                default:
                    setCookie({userName:user, token})
                    setLoggedIn(true);
                    break;
            }

        }).catch(error => {
            setLoading(false)
        })
    }
    if (loggedIn) {
        return <Redirect to={{ pathname: from.pathname }} />
    }

    return (

        <div className="login_container">
            <div className="login">
                <Form layout="vertical" style={{ width: 396, marginTop: 80 }} onFinish={onFinish}>
                    <Form.Item name="user"
                        
                        rules={[
                            {
                            validator: userValidator  
                            }
                        ]}>
                        <Input
                            style={{ backgroundColor: 'rgba(0,164,221,.1)', color: '#00A4DD' }}
                            size="large"
                            prefix={<UserOutlined style={{ color: '#00A4DD' }} />}
                            placeholder="用户名"
                            autoComplete="off"
                        />
                    </Form.Item>

                    <Form.Item name="pwd"
                        rules={[
                            {
                            validator: pwdValidator  
                            }
                        ]}>
                        <Input
                            style={{ backgroundColor: 'rgba(0,164,221,.1)', color: '#00A4DD' }}
                            type="password"
                            size="large"
                            prefix={<LockOutlined style={{ color: '#00A4DD' }} />}
                            placeholder="密码"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button block style={{ backgroundColor: '#00A4DD', color: '#FFFFFF', border: 'none' }} size="large" type="primary" htmlType="submit" loading={loading}>登录</Button>
                        <Link to={{
                            pathname: '/reg'
                        }}>
                            <div className="reg_link">注册账号</div>
                        </Link>
                        
                    </Form.Item>
                </Form>
            </div>

        </div>
        
    );
}