import axios from 'axios';
import { message } from 'antd';


export function httpGet(url, params) {
    return axios.get(url, { params }).then(res => {
        const { status } = res
        const { code } = res.data;
        if (status !== 200) {
            message.error('处理失败')
            throw new Error('不为成功响应200')
        }

        if (code === undefined) { // 如果处理失败
            message.error('处理失败');
            throw new Error('code不为0,处理失败');
        }

        return res.data;
    }).catch(error => {
        message.error('处理失败')
    })
}

export function httpPost(url, params) {
    return axios.post(url, params).then(res => {
        const { status } = res
        const { code = undefined } = res.data;
        
        if (status !== 200) {
            message.error('处理失败')
            throw new Error('不为成功响应200')
        }

        if (code === undefined) {// 如果处理失败
            
            throw new Error('返回code不为0,处理失败');
        } 

        return res.data;
    }).catch(error => {
        message.error('处理失败');
    })
}

