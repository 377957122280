import Home from './Home';

//业务模块
import MyFiles from './MyFiles';
import ShareFiles from './ShareFiles';
import DownloadRankingList from './DownloadRankingList';
import MyImageHosting from './ShareFiles/MyImageHosting';

//通用模块
import E404 from './error/E404';
import E403 from './error/E403';
import Login from './Login';
import Reg from './Reg'
import Logout from './Logout'
import ImageSharePage from './ImageSharePage';



export const routes = [
  {
    path: '/',
    exact: true,
    component: MyFiles
  },
  {
    path: '/myFiles',
    exact: true,
    component: MyFiles
  },
  {
    path: '/shareFiles',
    exact: true,
    component: ShareFiles
  },
  {
    path: '/myShareImages',
    exact: true,
    component: MyImageHosting
  },
  {
    path: '/downloadRankingList',
    exact: true,
    component: DownloadRankingList
  },
  {
    path: '/403',
    exact: true,
    component: E403
  },
  {
    path: '*',
    exact: true,
    component: E404
  }
];

export const mainRoutes = [
  {
    path: '/login',
    exact: true,
    component: Login
  },
  {
    path: '/reg',
    exact: true,
    component: Reg
  },
  {
    path: '/logout',
    exact: true,
    component: Logout
  },
  {
    path: '/share',
    exact: true,
    component: ImageSharePage
  },
  {
    path: '/404',
    exact: true,
    component: E404,
  }
];
