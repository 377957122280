import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getCookie } from '../../utils/utils';

export default function PrivateRoute({ children, ...rest }) {
    const token = getCookie('token');
    const userName = getCookie('userName');
    return (
        <Route
            {...rest}
            render={({ location }) =>
                token && userName ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: `/login`,
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}