import { httpGet, httpPost } from '../utils/http'

const baseUrl = 'api'
// 用户相关接口

// 注册 
export const register = ({email, firstPwd, nickName, phone, userName}) => {
   return httpPost(`${baseUrl}/reg`, {email, firstPwd, nickName, phone, userName})
}

// 登录 
export const login = ({user, pwd}) => {
    return httpPost(`${baseUrl}/login`, {user, pwd})
}

// 获取用户文件数量 
export const getUserFilesCount = ({token, user}) => {
    return httpPost(`${baseUrl}/myfiles?cmd=count`, {token, user})
}

// 获取用户文件信息 
export const getUserFilesInfo = ({token, user, count, start}) => {
    return httpPost(`${baseUrl}/myfiles?cmd=normal`, {token, user, count, start})
}

// 按下载量升序显示用户文件列表
export const getUserFilesInfoByPvAsc = ({token, user, count, start}) => {
    return httpPost(`${baseUrl}/myfiles?cmd=pvasc`, {token, user, count, start})
}

// 按下载量降序显示用户文件列表
export const getUserFilesInfoByPvDesc = ({token, user, count, start}) => {
    return httpPost(`${baseUrl}/myfiles?cmd=pvdesc`, {token, user, count, start})
}

// 获取共享列表文件数量
export const getUserShareFilesCount = () => {
    return httpGet(`${baseUrl}/sharefiles?cmd=count`, {})
}

// 获取共享列表文件信息
export const getUserShareFilesInfo = ({count, start}) => {
    return httpPost(`${baseUrl}/sharefiles?cmd=normal`, {count, start})
}

// 用户分享文件
export const userShareFile = ({token, user, md5, filename}) => {
    return httpPost(`${baseUrl}/dealfile?cmd=share`, {token, user, md5, filename})
}

// 用户删除文件
export const userDeleteFile = ({token, user, md5, filename}) => {
    return httpPost(`${baseUrl}/dealfile?cmd=del`, {token, user, md5, filename})
}

// 用户文件下载标志处理
export const userDownloadFileFlag = ({token, user, md5, filename}) => {
    return httpPost(`${baseUrl}/dealfile?cmd=pv`, {token, user, md5, filename})
}

// 用户取消分享文件
export const userCancelShareFile = ({user, md5, filename}) => {
    return httpPost(`${baseUrl}/dealsharefile?cmd=cancel`, {user, md5, filename})
}

// 用户转存分享文件
export const userSaveShareFile = ({user, md5, filename}) => {
    return httpPost(`${baseUrl}/dealsharefile?cmd=save`, {user, md5, filename})
}

// 分享文件下载标志处理
export const shareFileDownloadFlag = ({user, md5, filename}) => {
    return httpPost(`${baseUrl}/dealsharefile?cmd=pv`, {user, md5, filename})
}

// 获取分享文件下载榜
export const getShareFileDownloadRankingList = ({count, start}) => {
    return httpPost(`${baseUrl}/sharefiles?cmd=pvdesc`, {count, start})
}

// 上传文件前检查文件md5
export const checkMD5OfFile = ({token, md5, filename, user}) => {
    return httpPost(`${baseUrl}/md5`, {token, md5, filename, user})
}

// 获取我的图片分享信息
export const getShareImageHostingInfo = ({token, user, start, count}) => {
    return httpPost(`${baseUrl}/sharepic?cmd=normal`, {token, user, start, count})
}

// 图片分享
export const shareMyImage = ({token, user, md5, filename}) => {
    return httpPost(`${baseUrl}/sharepic?cmd=share`, {token, user, md5, filename})
}

// 根据urlmd5获取分享图片
export const browseShareImage = ({urlmd5}) => {
    return httpPost(`${baseUrl}/sharepic?cmd=browse`, {urlmd5})
}

// 取消图片分享
export const cancelShareImage = ({urlmd5, token, user}) => {
    return httpPost(`${baseUrl}/sharepic?cmd=cancel`, {urlmd5, token, user})
}