import React, { useEffect, useState } from 'react';
import { CloudUploadOutlined, ShareAltOutlined, ClearOutlined, ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message, Tag, Select } from 'antd';

import { useLocation, Redirect, Link } from 'react-router-dom';
import axios from 'axios'

import { getUserFilesCount, getUserFilesInfo, userShareFile, userDeleteFile, userCancelShareFile,
     userDownloadFileFlag, getUserFilesInfoByPvAsc, getUserFilesInfoByPvDesc, shareMyImage } from '../../apis/user';
import { getCookie, getFileSize, isPic } from '../../utils/utils'
import FileUploadModal from './FileUploadModal'
import { HOST } from '../../constants/config'


const { confirm } = Modal
const { Option } = Select

export default function MyFiles(props) {

    const columns = [
        {
            title: '序号',
            width: 50,
            align: 'center',
            // fixed: 'left',
            render: (text, item, index) => <div style={{ width: 50 }}>{++index}</div>
        },
        {
            title: '文件名',
            width:100,
            dataIndex: 'file_name',
            align: 'center',
            // fixed: 'left'
        },
        {
            title: '分享状态',
            width:100,
            align: 'center',
            dataIndex: 'share_status',
            render: (share_status, item) => {
                return (
                    <div>
                        {share_status === 1? <Tag color="green">已共享</Tag> : <Tag color="blue">未共享</Tag>}
                    </div>
                )
            } 
        },
        {
            title: '下载量',
            width:100,
            align: 'center',
            dataIndex: 'pv'
        },
        {
            title: '文件大小',
            width:120,
            align: 'center',
            dataIndex: 'size',
            render: (size, item) => <div style={{ width: 100 }}>{getFileSize(size)}</div>
        },
        {
            title: '文件类型',
            width:100,
            align: 'center',
            dataIndex: 'type'
        },
        {
            title: '创建时间',
            width:100,
            align: 'center',
            dataIndex: 'create_time'
        },
        {
            title: '操作',
            align: 'center',
            // fixed: 'right',
            render: (text, item) => {
                return (
                    <>
                        <Button type="primary" size="small" color="green" onClick={() => onDownloadFile(item)}>下载</Button>
                        {item.share_status === 0 ?
                        <Button style={{ marginLeft: 6 }} type="primary" color="blue" size="small" onClick={() => onShareFile(item)} >共享文件</Button>
                        : <Button style={{ marginLeft: 6 }} danger ghost size="small" onClick={() => onCancelShareFile(item)} >取消共享</Button> }
                        <Button style={{ marginLeft: 6 }} type="primary" danger size="small" onClick={() => onDeleteFile(item)}>删除文件</Button>
                    </>
                )
            }
        }
    ]

    // 表格checkbox配置
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows)
        }
    };
    
    // 点击刷新按钮时(做一下防抖)
    const onRefreshBtnClick = () => {
        if (refreshTimer) {
            console.log('刷新按钮冷却中...')
            return 
        }
        refreshTable();
        setRefreshTimer(setTimeout(() => {
            setRefreshTimer(null)
        }, 1000))
    }
    
    // 刷新表格
    const refreshTable = () => {

        const queryData = refreshType[selectRefreshTypeKey].queryData
        
        // getUserFilesCount({token, user})
        // .then(data => {
        //     setTotal(data.num)
        // })
        queryData({token, user, count: pageConfig.page_size, start: 0})
        .then(data => {
            const { code = 0, files = [], total } = data;

            if(code === 1){
                message.error('登录已失效,请重新登录')
                return 
            }
            
            setData(files);
            setTotal(total)
            message.success('刷新表格成功')
        })
    }

    // 下载文件
    const onDownloadFile = (item) => {
        
        const { url, file_name, md5 } = item
        
        confirm({
            title: '确定下载该文件吗?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                axios.get(url, {responseType: 'blob'})
                .then(res => {
                    const { data } = res
                    // 向后端发送一个文件下载完成标志
                    userDownloadFileFlag({token, user, md5, filename:file_name})
                    .then(data => {
                        console.log('下载完成标志:', data)
                    })

                    saveAsFile({data, file_name})
                    refreshTable()
                    
                })
            },
            onCancel() {
              console.log('取消下载文件');
            },
        });
        
    }

    // 下载文件到本地并自动点击
    const saveAsFile = ({data, file_name}) => {
        
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", file_name);

        document.body.appendChild(link);
        link.click();
    }
    
    // 分享文件
    const onShareFile = (item) => {
        userShareFile({token, user, md5: item.md5, filename: item.file_name})
        .then(data => {
            const { code } = data
            switch (code) {
                case 1:
                    message.error('共享文件失败')
                    break;
                case 3:
                    message.error('该文件已被分享')
                    break;
                case 4:
                    message.error('token验证失败')
                    break;
                // 默认为0 
                default:
                    message.success('共享文件成功')
                    refreshTable()
                    break;
            }
        })
    } 
    // 取消分享文件
    const onCancelShareFile = (item) => {
        userCancelShareFile({user, md5: item.md5, filename: item.file_name})
        .then(data => {
            const { code } = data
            code === 0 ? message.success('文件取消分享成功') : message.error('文件取消分享失败');
            // 刷新表格
            refreshTable()
        })
    }

    // 复制提取成功的图片url
    const copyImageUrl = (urlmd5) => {
        
        let transfer = document.createElement('input');
        document.body.appendChild(transfer);
        transfer.value = getImageShareUrl(urlmd5)  // 这里表示想要复制的内容
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
        }
        transfer.blur();
        message.success('提取链接已复制到剪切板')
        document.body.removeChild(transfer);      
    }

    // 批量分享图片到图床(暂时只支持单张分享)
    const shareMyImages = () => {
        // console.log('点击了批量分享图片')
        
        const selectedCounts = selectedRows.length
        // 如果未选中 提示不支持
        if (selectedCounts === 0) {
            return message.error('请先选中图片后再进行分享', 1)
        }
        // 如果选择的图片数大于1 提示暂不支持批量分享
        if (selectedCounts > 1) {
            return message.error('暂不支持批量分享', 1)
        }
        // // 如果选中的文件类型不是图片 提示无法放到图床
        const selectedFile = selectedRows[0]
        if (!isPic(selectedFile.type) ) {
            return message.error('该文件不是图片,无法分享到图床', 1)
        }
        const { file_name:filename, md5 } = selectedFile
        shareMyImage({token, user, filename, md5}).then(data => {
            // console.log('分享图片接口返回数据:', data)
            const { code, urlmd5 = "" } = data
            // 如果code为0 为成功
            if (code === 0) {
                confirm({
                    title: '分享链接',
                    content: (<div>图片的链接信息: { getImageShareUrl(urlmd5) }</div>),
                    onOk() {
                        copyImageUrl(urlmd5)
                        console.log('OK');
                    },
                    onCancel() {
                      console.log('Cancel');
                    },
                });
            }else{
                let errMsg = code === 1 ? '接口调用失败' : 'token验证失败'
                message.error(`${errMsg}`)
            }
            
        })


        
    }
    // 选择器的值改变时
    const handleSelectChange = (item) => {
        const { key } = item 
        setSelectRefreshTypeKey(key)
    }

    // 删除文件
    const onDeleteFile = (item) => {
        userDeleteFile({token, user, md5: item.md5, filename: item.file_name})
        .then(data => {
            const { code } = data
            code === 0 ? message.success('文件删除成功') : message.error('文件删除失败')
            refreshTable()
        })
    }
    // 显示文件上传modal
    const showUploadModal = () => {
        setUploadVisible(true)
    }
    // 隐藏文件上传modal
    const hideUploadModal = () => {
        setUploadVisible(false)
    }
    // 多选时分享文件按钮(暂时不用,只能选中单个后分享)
    const shareFiles = () => {
        const selectedCounts = selectedRows.length
        // 如果未选中 提示不支持
        if (selectedCounts === 0) {
            return message.error('请先选中文件后再进行分享', 1)
        }
        // 如果选择的文件数大于1 提示暂不支持批量分享
        if (selectedCounts > 1) {
            return message.error('暂不支持批量分享', 1)
        }
        // 如果选中的文件 已被分享, 提示 该文件已被分享
        const selectedFile = selectedRows[0]
        if (selectedFile.share_status === 1) {
            return message.error('该文件已被分享', 1)
        }
        // 进行文件分享
        userShareFile({token, user, md5: selectedFile.md5, filename: selectedFile.file_name })
        .then(data => {
            const { code } = data  
            if (code === 0) {
                message.success('文件共享成功', 1)
                refreshTable()
            }
        })
    }
    // 批量删除
    const deleteFiles = () => {
        const selectedCounts = selectedRows.length
        // 如果未选中
        if (selectedCounts === 0) {
            return message.error('请先选中文件后再进行删除', 1)
        }
        // 弹出确认框
        confirm({
            title: '是否确认删除文件?',
            icon: <ExclamationCircleOutlined />,
            content: '此操作不可撤销,是否继续?',
            onOk() {
                Promise.all(selectedRows.map(item => {
                    const { md5, file_name: filename} = item;
                    return userDeleteFile({ token, user, md5, filename})
                })).then((result) => {
                    message.success('删除文件成功', 1)
                    refreshTable()
                })
                
            },
            onCancel() {
                console.log('用户取消操作')
            }
        })
    }
    // 将 主机地址和 urlmd5等拼接成图片提取链接
    const getImageShareUrl = (urlmd5) => {
        return `${window.location.hostname}:${window.location.port}/share?urlmd5=${urlmd5}`
    }

    // 页码更改时
    const changePage = (page) => {
        const start = (page-1) * pageConfig.page_size

        setPage({ ...pageConfig, page_no: page})
        getUserFilesInfo({token, user, count: pageConfig.page_size, start: start})
        .then(data => {
            const {code, total, files = []} = data
            // console.log('获取我的文件响应数据', data)
            setData(files)
            setTotal(total)
            
        }).catch(error => {
            console.log('获取排行榜响应数据失败', error)
        })
        
    }


    const [token, setToken] = useState(getCookie('token'))
    const [user, setUser] = useState(getCookie('userName'))
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [selectionType, setSelectionType] = useState('checkbox');
    const [uploadVisible, setUploadVisible] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    let [refreshTimer, setRefreshTimer] = useState(null)
    // 刷新时获取数据的方式(1正常/2下载量升序/3下载量降序)
    const refreshType = [
        {
            key: 0,
            value: '正常排序',
            queryData: getUserFilesInfo
        },{
            key: 1,
            value: '按下载量升序排序',
            queryData: getUserFilesInfoByPvAsc
        },{
            key: 2,
            value: '按下载量降序排序',
            queryData: getUserFilesInfoByPvDesc
        }
    ]
    const [selectRefreshTypeKey, setSelectRefreshTypeKey] = useState(0)


    const [pageConfig, setPage] = useState({
        total_page: 0, // 总页数
        page_no: 1, // 当前页码
        page_size: 10 // 每页数据条数
    });

    // 分页设置
    const pagination = {
        position: ['bottomRight'],
        size: 'small',
        defaultCurrent: 1,
        total: total,
        pageSize: pageConfig.page_size,
        current: pageConfig.page_no,
        showTotal: total => `共${total}条`,
        onChange: changePage
    }
    
    // 获取用户文件数量
    useEffect(() => {
        getUserFilesCount({token, user})
        .then(data => {
            
            const {code, total} = data
            // console.log('获取我的文件数量:', total)
            if (code === 0 && total !== 0) {
                setTotal(total)
            }
        })
    
    }, [])
    
    // 获取文件数据
    useEffect(() => {
        getUserFilesInfo({token, user, start: 0, count: pageConfig.page_size})
        .then(data => {

            // console.log('获取的我的文件数据:', data)

            const { code, total, files=[] } = data
            if (code === 0 && total === 0) {
                // count为0时不解析files
                setData([]);
                setTotal(0)
            }else{
                setData(files);
                setTotal(total)
            }
        })
    }, [])

    
    return (
        <div>
            <div style={{marginLeft: 10 + 'px', display: 'flex'}}>
                
                <Button 
                    icon={<CloudUploadOutlined />}
                    onClick={showUploadModal}
                >上传</Button>
                <Button 
                    style={{marginLeft: 20}}
                    icon={<ShareAltOutlined />}
                    onClick={shareFiles}
                >分享文件</Button>
                <Button 
                    style={{marginLeft: 20}}
                    icon={<ClearOutlined />}
                    onClick={shareMyImages}
                >分享图片</Button>
                <Button 
                    style={{marginLeft: 20}}
                    icon={<ClearOutlined />}
                    onClick={deleteFiles}
                >删除</Button>
                <Button 
                    style={{marginLeft: 20}}
                    icon={<ReloadOutlined />}
                    onClick={onRefreshBtnClick}
                >刷新</Button>

                <Select
                    style={{marginLeft: 20, width: 140}}
                    labelInValue
                    defaultValue={refreshType[0]}
                    onChange={handleSelectChange}
                >
                    {
                        refreshType.map(item => {
                            return <Option key={item.key}>{item.value}</Option>
                        })
                    }
                </Select>
            </div>
            
            <div className="container">
                <Table columns={columns}  
                    dataSource={data}
                    total={total} 
                    // scroll={{ x:1500 }} 
                    rowKey={(record, index) => index}
                    pagination={pagination}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }} 
                />
            </div>
            <FileUploadModal 
                visible={uploadVisible}  
                onOk={hideUploadModal}
                onRefresh={refreshTable} 
                token={token}
                user={user}
                footer={[
                    <Button onClick={hideUploadModal} key='submit'>关闭</Button>
                ]}      
            />

        </div>
    )
}