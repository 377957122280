import React, { useState, useEffect } from 'react'
import { Button, Table, message, Tag, Modal } from 'antd'
import { CloudUploadOutlined, ShareAltOutlined, ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios'

import { getUserShareFilesCount, getUserShareFilesInfo, userCancelShareFile, userSaveShareFile, shareFileDownloadFlag } from '../../apis/user'
import { getCookie, getFileSize } from '../../utils/utils'

const { confirm } = Modal

export default function ShareFiles() {

    const columns = [
        {
            title: '序号',
            width: 50,
            align: 'center',
            // fixed: 'left',
            render: (text, item, index) => <div style={{ width: 50 }}>{++index}</div>
        },
        {
            title: '用户名',
            width: 70,
            dataIndex: 'user',
            align: 'center',
            // fixed: 'left'
        },
        {
            title: '文件名',
            width:100,
            dataIndex: 'file_name',
            align: 'center',
            fixed: 'left'
        },
        {
            title: '分享状态',
            width:100,
            align: 'center',
            dataIndex: 'share_status',
            render: (share_status, item) => {
                return (
                    <div>
                        {share_status === 1? <Tag color="green">已共享</Tag> : <Tag color="blue">未共享</Tag>}
                    </div>
                )
            } 
        },
        {
            title: '下载量',
            width:100,
            align: 'center',
            dataIndex: 'pv'
        },
        {
            title: '文件大小',
            width:120,
            align: 'center',
            dataIndex: 'size',
            render: (size, item) => <div style={{ width: 100 }}>{getFileSize(size)}</div>
        },
        {
            title: '文件类型',
            width:100,
            align: 'center',
            dataIndex: 'type'
        },
        {
            title: '创建时间',
            width:100,
            align: 'center',
            dataIndex: 'create_time'
        },
        {
            title: '操作',
            align: 'center',
            // width: 300,
            // fixed: 'right',
            render: (text, item) => {
                return (
                    <>
                        <Button type="primary" size="small" color="green" onClick={() => onDownloadFile(item)}>下载</Button>
                        <Button style={{ marginLeft: 6 }} type="primary" color="red" size="small" onClick={() => onSaveFile(item)}>转存</Button>
                    </>
                )
            }
        }
    ]

    // 表格checkbox配置
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows)
        }
    };

    // 点击刷新按钮时(做一下防抖)
    const onRefreshBtnClick = () => {
        if (refreshTimer) {
            console.log('刷新按钮冷却中...')
            return 
        }
        refreshTable();
        setRefreshTimer(setTimeout(() => {
            setRefreshTimer(null)
        }, 1000))
    }
      
    // 刷新表格
    const refreshTable = () => {
        getUserShareFilesCount()
        .then(data => {
            setTotal(data.num)
        }).catch(error => {
            message.error('获取共享文件数量失败')
        })

        getUserShareFilesInfo({count: 10, start: 0})
        .then(data => {
            const { code, files = [] } = data;
            setData(files);
            message.success('刷新表格成功')
        }).catch(error => {
            message.error('获取共享文件信息失败')
        })
    }

    // 下载文件
    const onDownloadFile = (item) => {
        
        const { url, file_name, md5 } = item
        
        confirm({
            title: '确定下载该文件吗?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                axios.get(url, {responseType: 'blob'})
                .then(res => {
                    const { data } = res
                    // 向后端发送一个共享文件下载完成标志
                    shareFileDownloadFlag({ user, md5, filename:file_name})
                    .then(data => {
                        console.log('下载完成标志:', data)
                    })

                    saveAsFile({data, file_name})
                    refreshTable()
                    
                })
            },
            onCancel() {
              console.log('取消下载文件');
            },
        });   
    }

    // 批量下载图片
    const downloadFiles = () => {
        const selectedCounts = selectedRows.length
        // 如果未选中 提示不支持
        if (selectedCounts === 0) {
            return message.error('请先选中文件后再进行下载', 1)
        }
        // 如果选择的文件数大于1 提示暂不支持批量下载
        if (selectedCounts > 1) {
            return message.error('暂不支持批量下载', 1)
        }
        // 选中文件进行下载
        const selectedFile = selectedRows[0]
        
        confirm({
            title: '确定下载该文件吗?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                axios.get(selectedFile.url, {responseType: 'blob'})
                .then(res => {
                    const { data } = res
                    // 向后端发送一个共享文件下载完成标志
                    shareFileDownloadFlag({ user, md5: selectedFile.md5, filename: selectedFile.file_name})
                    .then(data => {
                        console.log('下载完成标志:', data)
                    })

                    saveAsFile({data, file_name: selectedFile.file_name})
                    refreshTable()
                    
                })
            },
            onCancel() {
              console.log('取消下载文件');
            },
        }); 
        
    }
    // 批量转存
    const saveFiles = () => {
        const selectedCounts = selectedRows.length
        // 如果未选中 提示不支持
        if (selectedCounts === 0) {
            return message.error('请先选中文件后再进行转存', 1)
        }
        // 如果选择的文件数大于1 提示暂不支持批量转存
        if (selectedCounts > 1) {
            return message.error('暂不支持批量转存', 1)
        }
        // 选中文件
        const selectedFile = selectedRows[0]

        confirm({
            title: '确定转存该文件吗?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                 
                userSaveShareFile({ user, md5: selectedFile.md5, filename: selectedFile.file_name})
                .then(data => {
                    const { code } = data
                    switch (code) {
                        // 转存成功
                        case 0:
                            message.success('文件转存成功')
                            break;
                        // 转存失败
                        case 1:
                            message.error('文件转存失败')
                            break;
                        // code为5时,文件已存在
                        default:
                            message.error('文件已存在')
                            break;
                    }
                })

                refreshTable()
        
            },
            onCancel() {
              console.log('取消转存文件');
            },
        }); 
        
    }
    // 批量取消共享
    const cancelShareFiles = () => {
        const selectedCounts = selectedRows.length
        // 如果未选中 提示不支持
        if (selectedCounts === 0) {
            return message.error('请先选中文件后再进行取消共享', 1)
        }
        // 如果选择的文件数大于1 提示暂不支持批量取消共享
        if (selectedCounts > 1) {
            return message.error('暂不支持批量取消共享', 1)
        }
        // 选中文件
        const selectedFile = selectedRows[0]
        confirm({
            title: '确定取消该文件共享吗?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                userCancelShareFile({user, md5: selectedFile.md5, filename: selectedFile.file_name})
                .then(data => {
                    const { code } = data
                    code === 0 ? message.success('文件取消分享成功') : message.error('文件取消分享失败');
                    // 刷新表格
                    refreshTable()
                })
            },
            onCancel() {
                console.log('用户取消操作')
            }
        })
    }

    // 下载文件到本地并自动点击
    const saveAsFile = ({data, file_name}) => {
        
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", file_name);

        document.body.appendChild(link);
        link.click();
    }
    // 转存文件
    const onSaveFile = (item) => {
        userSaveShareFile({user, md5: item.md5, filename: item.file_name})
        .then(data => {
            const { code } = data
            switch (code) {
                // 转存成功
                case 0:
                    message.success('文件转存成功')
                    break;
                // 转存失败
                case 1:
                    message.error('文件转存失败')
                    break;
                // code为5时,文件已存在
                default:
                    message.error('文件已存在')
                    break;
            }
        })
    }

    // 页面更改时
    const changePage = (page) => {
        const start = (page-1) * pageConfig.page_size

        setPage({ ...pageConfig, page_no: page})
        getUserShareFilesInfo({count: pageConfig.page_size, start: start})
        .then(data => {
            const {code, total, files = []} = data
            // console.log('获取的排行榜响应数据', data)
            setData(files)
            setTotal(total)   
        }).catch(error => {
            console.log('获取排行榜响应数据失败', error)
        })
        
    }

    const [total, setTotal] = useState(0)
    const [data, setData] = useState([])
    const [user, setUser] = useState(getCookie('userName'))
    const [selectionType, setSelectionType] = useState('checkbox')
    const [selectedRows, setSelectedRows] = useState([])
    const [refreshTimer, setRefreshTimer] = useState(null)

    const [pageConfig, setPage] = useState({
        total_page: 0, // 总页数
        page_no: 1, // 当前页码
        page_size: 10 // 每页数据条数
    });

    // 分页设置
    const pagination = {
        position: ['bottomRight'],
        size: 'small',
        defaultCurrent: 1,
        total: total,
        pageSize: pageConfig.page_size,
        current: pageConfig.page_no,
        showTotal: total => `共${total}条`,
        onChange: changePage
    }

    // 获取共享文件数量
    useEffect(() => {
        getUserShareFilesCount()
        .then(data => {
            // console.log('共享文件数量接口返回的数据:', data)
            const {code, total} = data
            // console.log('获取共享文件数量:', total)
            if (code === 0 && total !== 0) {
                setTotal(total)
            }
        }).catch(error => {
            message.error('获取共享文件数量失败')
        })
    
    }, [])
    
    // 获取文件数据
    useEffect(() => {
        getUserShareFilesInfo({start: 0, count: pageConfig.page_size})
        .then(data => {

            // console.log('获取的共享文件数据:', data)

            const { code = undefined, total, files=[] } = data

            if (code === undefined) {
                message.error('获取共享文件数据失败')
            }

            if (code === 0 && total === 0) {
                // count为0时不解析files
                setData([]);
            }else{
                setData(files);
            }
        }).catch(error => {
            message.error('获取共享文件信息失败')
        })
    }, [])


    return (
        <div>
            <div style={{marginLeft: 10 + 'px', display: 'flex'}}>
                
                <Button 
                    icon={<CloudUploadOutlined />}
                    onClick={saveFiles}
                >转存</Button>
                <Button 
                    style={{marginLeft: 20}}
                    icon={<ShareAltOutlined />}
                    onClick={downloadFiles}
                >下载</Button>
                
                <Button 
                    style={{marginLeft: 20}}
                    icon={<ReloadOutlined />}
                    onClick={onRefreshBtnClick}
                >刷新</Button>
            </div>
            
            <div className="container">
                <Table columns={columns}  
                    dataSource={data}
                    total={total} 
                    // scroll={{  }} 
                    rowKey={(record, index) => index}
                    pagination ={pagination}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }} 
                />
            </div>
        </div>
    )
}