import React, { useState, useEffect } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, Form } from 'antd';
import { useLocation, Redirect } from 'react-router-dom';
import { setCookie } from '../../utils/utils'

export default function Logout(props) {
    const [loggedIn, setLoggedIn] = useState(true);
    useEffect(() => {
        logout().then(() => {
            
            setLoggedIn(false);
        });
    }, []);

    const logout = () => {
        return new Promise((resolve, reject) => {
            // 将设置的cookie值覆盖为空字符串
            setCookie({userName: '', token: ''})
            resolve()
        })
    }

    if (!loggedIn) {
        return <Redirect to={{ pathname: '/login' }} />
    }

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 100 }}>
            正在退出登录...
        </div>
    );
}