import React, { useEffect, useState } from 'react';
import { LockOutlined, UserOutlined, MailOutlined, MobileOutlined, MehOutlined } from '@ant-design/icons';
import { Input, Button, Form, message } from 'antd';
import { useLocation, Redirect, Link } from 'react-router-dom';
import { register } from '../../apis/user';
import { md5Encrypt } from '../../utils/crypto';
import './index.scss'
import checkLoginToken from '../../auth/checkAuth';


export default function Reg(props) {
    const location = useLocation();
    const { from } = location.state || { from: { pathname: "/"} };
    // 检查cookies中是否有token
    const isLoggedIn = checkLoginToken()
    const [loggedIn, setLoggedIn] = useState(isLoggedIn);
    const [loading, setLoading] = useState(false);

    // 校验userName字段的校验器 4-16位
    const userNameValidator = (_, value) => {
        return value && value.length >= 4 && value.length <= 16 ? Promise.resolve() : Promise.reject('用户名长度为4-16位字符')
    }
    // 校验firstPwd字段的校验器 4-16位
    const pwdValidator = (_, value) => {
        return value && value.length >= 4 && value.length <= 16 ? Promise.resolve() : Promise.reject('密码长度为4-16字符') 
    }
    // 校验email字段的校验器(可不填)
    const emailValidator = (_, value) => {
        const reEmail = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9-.]+/
        return !value || value.length === 0 ? Promise.resolve() : ( reEmail.test(value) ? Promise.resolve() : Promise.reject('请输入正确的邮箱格式') )
    }

    // 校验nickName字段的校验器(1-16位)
    const nickNameValidator = (_, value) => {
        return value && value.length >= 1 && value.length <= 16 ? Promise.resolve() : Promise.reject('昵称长度为1-16位字符')
    }

    // 校验phone字段的校验器(可不填)
    const phoneValidator = (_, value) => {
        const rePhone = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
        return !value || value.length === 0 ? Promise.resolve() : (rePhone.test(value) ? Promise.resolve() : Promise.reject('请输入正确的手机号码'))
    }

    const onFinish = (values) => {
        
        const { userName, firstPwd, nickName, phone='', email=''} = values;
        const key = 'register'
        message.loading({content: '正在注册...', key})
        
        setLoading(true);
        // 对密码进行md5加密
        const encryptPwd = md5Encrypt(firstPwd);
        register({ userName, firstPwd: encryptPwd, nickName, phone, email }).then(data => {
            setLoading(false);
            const { code } = data
            switch (code) {
                case 0:
                    message.success({content: '注册成功', key})
                    break;
                case 1:
                    message.error({content: '注册失败', key})
                    break;
                default:
                    message.error({content: '该用户已存在', key})
                    break;
            }
            
        }).catch(error => {
            setLoading(false)
            message.error('处理失败')
        })
    }

    if (loggedIn) {
        return <Redirect to={{ pathname: from.pathname }} />
    }

    return (
        <div className="reg_container">
            <div className="reg">
                <Form layout="vertical" style={{ width: 396, marginTop: 90 }} onFinish={onFinish}>
                    <Form.Item name="userName"
                        
                        rules={[
                            {
                            validator: userNameValidator  
                            }
                        ]}>
                        <Input
                            style={{ backgroundColor: 'rgba(0,164,221,.1)', color: '#00A4DD' }}
                            size="large"
                            prefix={<UserOutlined style={{ color: '#00A4DD' }} />}
                            placeholder="用户名"
                            autoComplete="off"
                        />
                    </Form.Item>

                    <Form.Item name="firstPwd"
                        rules={[
                            {
                            validator: pwdValidator  
                            }
                        ]}>
                        <Input
                            style={{ backgroundColor: 'rgba(0,164,221,.1)', color: '#00A4DD' }}
                            type="password"
                            size="large"
                            prefix={<LockOutlined style={{ color: '#00A4DD' }} />}
                            placeholder="密码"
                        />
                    </Form.Item>

                    <Form.Item name="email"
                        rules={[
                            {
                            validator: emailValidator  
                            }
                        ]}>
                        <Input
                            style={{ backgroundColor: 'rgba(0,164,221,.1)', color: '#00A4DD' }}
                            size="large"
                            type="email"
                            prefix={<MailOutlined style={{ color: '#00A4DD' }} />}
                            placeholder="邮箱(非必填)"
                            autoComplete="off"
                        />
                    </Form.Item>

                    <Form.Item 
                        name="phone"
                        rules={[
                            {
                            validator: phoneValidator  
                            }
                        ]}>
                        <Input
                            style={{ backgroundColor: 'rgba(0,164,221,.1)', color: '#00A4DD' }}
                            size="large"
                            prefix={<MobileOutlined style={{ color: '#00A4DD' }} />}
                            placeholder="手机号码(非必填)"
                            autoComplete="off"
                        />
                    </Form.Item>

                    <Form.Item name="nickName"
                        rules={[
                            {
                            validator: nickNameValidator  
                            }
                        ]}>
                        <Input
                            style={{ backgroundColor: 'rgba(0,164,221,.1)', color: '#00A4DD' }}
                            size="large"
                            prefix={<MehOutlined style={{ color: '#00A4DD' }} />}
                            placeholder="用户昵称"
                            autoComplete="off"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button block style={{ backgroundColor: '#00A4DD', color: '#FFFFFF', border: 'none' }} size="large" type="primary" htmlType="submit" loading={loading}>注册</Button>
                        <Link to={{
                            pathname: '/login'
                        }}>
                            <div className="login_link">去登录</div>
                        </Link>
                        
                    </Form.Item>
                </Form>
            </div>

        </div>
        
    )

    
}
