import React, { useState } from 'react';
import { Modal, Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import axios from 'axios'
import SparkMD5 from 'spark-md5'
import { checkMD5OfFile } from '../../../apis/user'

export default function FileUploadModal(props) {
    const { visible, onOk, token, user, footer, onRefresh } = props
    const [fileList, setFileList] = useState([])

    // 上传组件需要的属性
    const uploadProps = {
        className: 'upload-list-inline', 
        maxCount: 1,
        
        // 上传文件被改变时
        onChange: ({file, fileList}) => {
            // 被beforeUpload拦截的文件没有 status属性
            // // TODO: 注意 status为 uploading的文件不要移除!!!
            // // 清除被拦截的文件
            // if (file.md5 === '') {
            //     fileList = []
            // }
            // // 清除上传成功 上传失败的文件
            // const clearStatus = ['done', 'error']
            // if (clearStatus.includes(file.status)) {
            //     fileList = []
            // }
            // setFileList([...fileList])
            // TODO: 上面的代码弄了以后不触发onChange了,本来是想弄个上传成功后自动移除的,恼火啊
        },
        
    }
    // 上传文件之前的逻辑
    const beforeUpload = (file) => {
        return new Promise((resolve, reject) => {
            let isAbortUpload ;
            const isLt10M = file.size / 1024 / 1024 < 10
            if (!isLt10M) {
                message.error('文件大小不能大于10M')
                reject()
            }
            getMD5OfFile(file).then(md5 => {
                checkMD5OfFile({ token, user, filename: file.name, md5})
                .then(data => {
                    // console.log('md5秒传的返回结果为:', data)
                    const { code } = data
                    switch (code) {
                        // 秒传成功
                        case 0:
                            file.md5 = ''
                            message.success('秒传成功')
                            onRefresh()
                            reject()
                            break;
                        // 秒传失败时需要上传文件
                        case 1:
                            file.md5 = md5
                            // console.log('设置需发送给后端的md5值为:',md5)
                            message.success({content: '等待文件上传', key: 'upload' })
                            resolve()
                            break;
                        // token校验失败
                        case 4:
                            file.md5 = ''
                            message.error('token校验失败,取消文件上传')
                            reject()
                            break;
                        // 5.文件已存在
                        default:
                            file.md5 = ''
                            message.error('文件已存在,取消文件上传')
                            reject()
                            break;
                    }
                })  
            })
        
        })
                            
    }

    // 自定义上传逻辑
    const customRequest = (options) => {
        // console.log('自定义上传', options)
        const { onSuccess, onError, file, onProgress } = options
        // 上传地址
        const action = 'api/upload'
        // 需要添加到formData中的参数
        const uploadParams = {
            user: user,
            md5: file.md5,
            size: file.size
        }

        let formData = new FormData();
        
        formData.append('file', file);

        for(let param in uploadParams) {
            formData.append(param, uploadParams[param])
        }
        // console.log('打印自定义上传时存储的md5:', uploadParams.md5)
        
        // 发送上传文件请求
        axios.post(action, formData)
        .then(res=> {
            const { code } = res.data;
            // 打印发送的请求数据
            if (code === 0) {
                message.success({content: '文件上传成功', key: 'upload'})
                onSuccess()
                onRefresh()
            }else{
                onError()
            }
        })
        .catch(error => {
            message.error('图片上传失败')
            onError()

        })
    }

    // 获取文件内容的md5值
    const getMD5OfFile = (file) => {
        return new Promise(function(resolve, reject){
            let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
            chunkSize = 2097152,   // 一次读取2MB
            chunks = Math.ceil(file.size / chunkSize),
            currentChunk = 0,
            spark = new SparkMD5.ArrayBuffer(),
            fileReader = new FileReader();

            const loadNext = () => {
                let start = currentChunk * chunkSize
                let end = start + chunkSize >= file.size ? file.size : start + chunkSize
                fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
            }

            fileReader.onload = function(e) {
                console.log(`read chunk ${currentChunk + 1} of ${chunks}`)
                spark.append(e.target.result)
                currentChunk ++
    
                if (currentChunk < chunks) {
                    loadNext()
                }else{
                    // console.log('文件读取完成')
                    const md5 = spark.end()
                    // console.log('产生的md5为:', md5)
                    resolve(md5)
                }
            }

            fileReader.onerror = function(e) {
                console.log('文件读取出错')
                reject('')
            }  
            loadNext()

        })
        
        

        
        
    }

    return (
        <Modal width={600} visible={visible} closable={false} okText="关闭" onOk={onOk} footer={footer}  >
            <div key={Math.random}>
                <Upload 
                    {...uploadProps} 
                    customRequest={customRequest}
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    showUploadList={true}
                >
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                    >
                        上传文件
                    </Button>
                </Upload>
            </div> 
        </Modal>
    )
}