import { HomeOutlined, LockOutlined, SettingOutlined, FolderOutlined, 
    FolderViewOutlined, AppstoreOutlined, BarsOutlined, FileImageOutlined } from '@ant-design/icons';


const menus = [{
    title: '我的文件',
    key: 'myFiles',
    icon: FolderOutlined,
    subMenus: [{
        title: '文件列表',
        icon: AppstoreOutlined,
        key: 'myFilesList',
        href: '/myFiles'
    }]
},
{
    title: '共享文件',
    key: 'shareFiles',
    icon: FolderViewOutlined,
    subMenus: [{
        title: '文件列表',
        icon: AppstoreOutlined,
        key: 'shareFilesList',
        href: '/shareFiles'
    },{
        title: '共享图片',
        icon: FileImageOutlined,
        key: 'myShareImages',
        href: '/myShareImages'
    },{
        title: '下载榜',
        key: 'downloadRankingList',
        icon: BarsOutlined,
        href: '/downloadRankingList'
    }]  
},
{
    title: '设置',
    icon: SettingOutlined,
    key: 'settings',
    subMenus: [{
        title: '退出登录',
        icon: LockOutlined,
        key: 'logout',
        href: '/logout'
    }]
}];


export default menus;