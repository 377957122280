import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { routes, mainRoutes } from './pages';
import NavMenu from './components/NavMenu';
import { ConfigProvider, message } from 'antd';
import './App.less';
import PrivateRoute from './components/PrivateRoute';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';

message.config({
  top: 10,
  duration: 1.5,
  maxCount: 3,
  rtl: true,
});

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <div className="App">
        <Router>
          <Switch>
            {
              mainRoutes.map(({ path, component: C, exact }) => {
                return <Route key={path} path={path} exact={exact}><C /></Route>
              })
            }

            <Route>
              <NavMenu />
              <Switch>
                {
                  routes.map(({ path, component: C, exact }) => {
                    return <PrivateRoute key={path} path={path} exact={exact}><div className="main-page"><C /></div></PrivateRoute>
                  })
                }
              </Switch>
            </Route>

          </Switch>

        </Router>

      </div>
    </ConfigProvider>
  );
}

export default App;
